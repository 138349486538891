import { Fragment, useRef, useState } from "react";

import classes from "./ContactForm.module.css";

const ContactForm = () => {
  const [formsubmitted, setFormSubmitted] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const subjectRef = useRef();

  const contactFormHandler = (e) => {
    e.preventDefault();

    setFormSubmitted(true);

    const enteredName = nameRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredMessage = messageRef.current.value;
    const subject = subjectRef.current.value;

    fetch("https://formsubmit.co/ajax/light2partymcr@gmail.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: enteredName,
        email: enteredEmail,
        subject: subject,
        message: enteredMessage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setTimeout(() => setFormSubmitted(false), 4000);
      })
      .catch((error) => {
        // better error handling needed in future
        console.log(error);
        return <h2>Sorry, something went wrong!</h2>;
      });
  };

  return (
    <section id={classes["contact-form"]}>
      {!formsubmitted && (
        <Fragment>
          <h2>Get in touch:</h2>

          <form action="https://formsubmit.co/light2partymcr@gmail.com" method="POST" onSubmit={contactFormHandler}>
            <input type="hidden" name="_subject" ref={subjectRef} value="New enquiry!"></input>
            <input id={classes.name} type="text" name="name" placeholder="Name:" ref={nameRef} required />
            <input id={classes.email} type="email" name="email" placeholder="Email:" ref={emailRef} required />
            <textarea id={classes.message} name="message" placeholder="Message:" ref={messageRef} required></textarea>
            <button id={classes.submit} type="submit">
              Send
            </button>
          </form>
        </Fragment>
      )}

      {formsubmitted && <h2 className={classes["thank-you"]}>Thank you. We'll be in touch!</h2>}
    </section>
  );
};

/* 
React router for thank you page with a redirect after 5 seconds?
OR Change of state on exisiting page, prevent default etc then submit
Will prevent default mess everything up?

BETTER ERROR HANDLING NEEDED IN FUTURE
*/

export default ContactForm;
